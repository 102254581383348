import React, { useEffect, useState, useRef, useContext } from 'react';
import { navigate, Link } from 'gatsby';
import { quoteContext } from '../../../provider';

import '../../assets/styles/pages/quote/step-one.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProgressBar from '../../components/quotes/progress-bar';
import QuoteNav from '../../components/quotes/quote-nav';
import GuidelinesPopup from '../../components/quotes/guidelines-popup';
import Camera from '../../images/quote/icon-camera.inline.svg';
import Upload from '../../images/quote/icon-upload.inline.svg';
import TrashBin from '../../images/quote/trash-bin.inline.svg';
import Plus from '../../images/quote/plus.inline.svg';
import BackArrow from '../../images/quote/icon-arrow-left.inline.svg';

/* global window */
const QuoteStepOne = () => {
  const uploadFiles = {};
  const [files, setFiles] = useState({});
  const [imageDrop, setimageDrop] = useState(false);
  const [guidelinesPopup, setGuidelinesPopup] = useState(false);
  const inputFileRef = useRef(null);
  let { quoteForm, addToQuoteForm } = useContext(quoteContext);

  useEffect(() => {
    if (Object.keys(quoteForm).length > 0 && Object.keys(files).length === 0) {
      setFiles(quoteForm);
    }
  });

  const handleBtnClick = () => {
    inputFileRef.current.click();
  }

  const handleInputChange = (event) => {
    const values = event.target.files;
    let summary = { ...values, ...files.uploadFiles };
    Object.keys(summary).map((i) => {
      const name = summary[i].name;
      uploadFiles[`${name}${i}`] = summary[i];
    });

    setFiles({
      ...files,
      uploadFiles
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addToQuoteForm({
      ...quoteForm,
      uploadFiles: files.uploadFiles
    });
    navigate('/quote/step-two');
  };

  const hasFiles = () => {
    if (typeof files !== 'undefined') {
      if (files.uploadFiles) {
        return (Object.keys(files.uploadFiles).length > 0);
      }
    }

    return false;
  };

  const disableBtn = () => {
    let disableClass = 'disable';
    if (hasFiles()) {
      disableClass = '';
    }

    return disableClass;
  };

  const removeImage = (name) => {
    let uploadFiles = { ...files.uploadFiles };
    Object.keys(uploadFiles).forEach(key => {
      if (name === key) {
        delete uploadFiles[key];
      }
    });

    setFiles({ uploadFiles });
  };

  return (
    <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
      <ProgressBar step="1" />
      <SEO title="DingGo | Car Quote - Submit Your Snap" description="Upload your photos and describe the damage to compare car repair quotes online, fast and free. Snap. Compare. Repair." />
      <div className="quote-wrapper step-one">
        <div className="container sm">
          <QuoteNav step="1" />
          <h1>Add Photos</h1>
          <p className="intro">
            Click the ‘Add Photos’ button to take or upload photos now.
          </p>
          <span
            href="#"
            className="open-guidelines"
            onClick={(e) => {
              e.preventDefault();
              setGuidelinesPopup(!guidelinesPopup);
            }}><Camera /> Review Photo Guidelines</span>
          <form onSubmit={(e) => { handleSubmit(e); }}>
            <div className={`file-input-wrapper ${hasFiles() ? '' : 'no-files'} ${imageDrop ? 'drop-active' : ''}`}>
              <input
                type="file"
                accept="image/*,image/heif,image/heic"
                ref={inputFileRef}
                multiple={true}
                onChange={(e) => { handleInputChange(e); }}
                onDragEnter={() => { setimageDrop(!imageDrop); }}
                onDragLeave={() => { setimageDrop(!imageDrop); }}
                onDrop={() => { setimageDrop(!imageDrop); }}
              />
              <div className={`upload-image-wrapper ${hasFiles() ? '' : 'no-files'}`}>
                {hasFiles() &&
                  Object.keys(files.uploadFiles).map((key) => {
                    const file = files.uploadFiles[key];
                    const src = URL.createObjectURL(file);
                    return (
                      <div className="upload-image" key={key}
                        onClick={() => { removeImage(key); }}>
                        <img src={src} width="250" />
                        <TrashBin />
                      </div>
                    );
                  })}
              </div>
              {!hasFiles() &&
                <div className="middle-column"></div>
              }
              <div className={`instructions-wrapper ${hasFiles() ? '' : 'no-files'}`} onClick={handleBtnClick}>
                <span className="btn-add-photos"><Upload /> Add Photos</span>
                <span className="drag-drop-instructions">or drag photos in</span>
                <span className="add-more"><Plus />Add another</span>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <Link to="/quote" className="arrow-cta"><BackArrow />Back</Link>
              <button className={`btn ${disableBtn()}`} type="submit" id="button-click-to-step-2">Next</button>
            </div>
          </form>
        </div>
      </div>
      <GuidelinesPopup guidelinesPopup={guidelinesPopup} setGuidelinesPopup={setGuidelinesPopup} />
    </Layout>
  );
};

export default QuoteStepOne;
