import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/base/popup.scss';
import CloseIcon from '../../images/icon-close.inline.svg';

const GuidelinesPopup = ({ guidelinesPopup, setGuidelinesPopup }) => {
  const data = useStaticQuery(graphql`
    query {
      GuidelineOne: file(relativePath: { eq: "quote/guideline-one.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      GuidelineTwo: file(relativePath: { eq: "quote/guideline-two.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      GuidelineThree: file(relativePath: { eq: "quote/guideline-three.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <div
        className={`popup-overlay ${guidelinesPopup ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          setGuidelinesPopup(!guidelinesPopup);
        }}
      />
      <div className={`popup-wrapper ${guidelinesPopup ? 'active' : ''}`}>
        <CloseIcon className="close-icon"
          onClick={(e) => {
            e.preventDefault();
            setGuidelinesPopup(!guidelinesPopup);
          }} />
        <h2 className="popup-title">Photo guidelines required to get you accurate repair quotes</h2>
        <div className="popup-body">
          <div className="photo-guidelines-wrapper">
            <div className="photo-guideline">
              <div className="image-wrapper">
                <Img fluid={data.GuidelineOne.childImageSharp.fluid} />
              </div>
              <div className="text-wrapper">
                <span className="alert">Most Important</span>
                <h3>1. Damage Including Surrounding Panels</h3>
                <p>From 4 paces away. Make sure the surrounding parts of the damaged area are visible</p>
              </div>
            </div>
            <div className="photo-guideline">
              <div className="image-wrapper">
                <Img fluid={data.GuidelineTwo.childImageSharp.fluid} />
              </div>
              <div className="text-wrapper">
                <h3>2. Damage Up Close and Front On</h3>
                <p>Standing 1 pace away take a front on photo of the damaged area</p>
              </div>
            </div>
            <div className="photo-guideline">
              <div className="image-wrapper">
                <Img fluid={data.GuidelineThree.childImageSharp.fluid} />
              </div>
              <div className="text-wrapper">
                <h3>3. Damage From An Angle</h3>
                <p>Standing 1 pace away take a photo of the damaged area from an angle</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuidelinesPopup;
